<template>
  <div>
    <!-- eslint-disable -->
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
    </b-overlay>
    <!-- Filters -->
    <contract-list-filters :role-options="roleOptions" />
    <!-- Table Container Card -->
    <b-card no-body class="my-2">
      <b-table
        class="position-relative"
        :hover="true"
        :items="records"
        responsive
        :fields="fields"
        show-empty
        empty-text="No existen"
        @sort-changed="sortChanged"
      >
        <!-- RESPONSABLE -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            {{ data.item.name }}
          </b-media>
        </template>
        <!-- DESARROLLADO POR -->
        <template #cell(responsible_sst)="data">
          <b-media vertical-align="center">
            {{ data.item.responsible_sst }}
          </b-media>
        </template>
        <template #cell(contractor_status)="data">
          <b-media vertical-align="center">
            <span v-if="data.item.contractor_status == 'draft'"> Borrador</span>
            <span v-else> Pendiente de revisión</span>
          </b-media>
        </template>
        <template #cell(is_finished)="data">
          <b-media vertical-align="center">
            <span v-if="data.item.is_finished">
              <b-badge pill variant="success">Si</b-badge></span
            >
            <span v-else> <b-badge pill variant="primary">No</b-badge> </span>
          </b-media>
        </template>
        <!-- FECHA EVALUACION-->
        <template #cell(expiration_date)="data">
          <b-media vertical-align="center">
            {{ moment(data.item.expiration_date).format("yyyy-MM-DD") }}
          </b-media>
        </template>

        <!-- EMPRESA -->
        <template #cell(business_name)="data">
          <b-media vertical-align="center">
            {{ data.item.company.business_name }}
          </b-media>
        </template>

        <template #cell(completion_percentage)="data">
          <b-media vertical-align="center">
            <b-badge
              pill
              :variant="
                data.item.completion_percentage < 60
                  ? 'danger'
                  : data.item.completion_percentage < 85
                  ? 'warning'
                  : 'success'
              "
              >{{ data.item.completion_percentage }}</b-badge
            >
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            size="sm"
            class=""
            @click="editAction(data)"
            v-if="userData.role != 'contratista'"
            v-b-tooltip.hover.v-success
            title="Editar"
            variant="flat-success"
          >
            <feather-icon icon="PenToolIcon" />
          </b-button>

          <b-button
            size="sm"
            class=""
            variant="flat-warning"
            v-b-tooltip.hover.v-warning
            title="Seguimiento"
            @click="viewContract(data.item.id)"
          >
            <feather-icon variant="" icon="ClipboardIcon" />
          </b-button>

          <b-button
            size="sm"
            class=""
            variant="flat-info"
            v-b-tooltip.hover.v-info
            v-if="data.item.is_finished"
            title="report"
            @click="reportContract(data.item.id)"
          >
            <feather-icon variant="" icon="PieChartIcon" />
          </b-button>

          <b-button
            size="sm"
            class=""
            variant="flat-danger"
            v-b-tooltip.hover.v-danger
            title="Eliminar"
            v-if="userData.role != 'contratista'"
            @click="deleteAction(data.item.id)"
          >
            <feather-icon variant="" icon="Trash2Icon" />
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col sm="3">
            <b-form-group
              label-cols="4"
              label-cols-md="4"
              label-size="md"
              label="Entradas"
              label-for="input-md"
            >
              <b-form-select
                v-model="showEntrie"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="entries"
                @change="changeSizePage"
              />
            </b-form-group>
          </b-col>
          <b-col sm="3" class="mt-75" style="font-size: 12px">
            <span> {{ totalElements }} Registros en total</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalElements"
              :per-page="showEntrie"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="cambioPagina"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BLink,
  BPagination,
  BFormSelect,
  BFormGroup,
  BOverlay,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  VBTooltip,
  BBadge,
} from "bootstrap-vue";
// import vSelect from 'vue-select'
import UserService from "@/services/UserService";
import ContractListFilters from "./ContractListFilters.vue";
import ContractService from "@/services/ContractService";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    ContractListFilters,
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BLink,
    BFormGroup,
    BPagination,
    BOverlay,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BBadge,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      records: [],
      fields: [
        { key: "name", label: "Nombre", sortable: true },
        { key: "responsible_sst", label: "Responsable", sortable: true },
        { key: "expiration_date", label: "Fecha Expiración", sortable: true },
        { key: "business_name", label: "Empresa", sortable: true },
        { key: "scope", label: "Alcance", sortable: true },
        { label: "Estado", key: "contractor_status", sortable: true },
        { label: "Terminado", key: "is_finished", sortable: true },
        { label: "Cumplimiento", key: "completion_percentage" },
        { key: "actions", label: "Acciones" },
      ],
      roleOptions: [
        { label: "Todos", value: null },
        { label: "Admin", value: "admin" },
        { label: "Contratista", value: "contratista" },
        { label: "Regulador", value: "regulador" },
      ],
      arrayFilters: [],
      roleFilter: null,
      nameFilter: null,
      lastNameFilter: null,
      emailFilter: null,
      currentPage: 1,
      entries: [10, 20, 50, 100],
      showEntrie: 10,
      totalElements: 0,
      is_filter: false,
      id: 0,
      sort: "id",
      order: "desc",
      showLoading: false,
      userData: {},
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    cambioPagina(e) {
      this.currentPage = e;
      this.getData();
    },
    editAction(data) {
      this.$router.push("/contract/edit/" + data.item.id);
    },
    async deleteAction(contract_id) {
      console.log("contract_id", contract_id);
      this.$swal({
        title: "¿Desea eliminar este contrato?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminalo",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          const resp = await ContractService.deleteContract(
            contract_id,
            this.$store
          );
          if (resp.success) {
            this.currentPage = 1;
            this.getData();
            this.$swal({
              icon: "success",
              title: "Eliminado!",
              text: "El contrato ha sido eliminado",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }
        }
      });
    },
    changeSizePage() {
      this.getData();
    },
    sortChanged(data) {
      this.sort = data.sortBy;
      if (data.sortDesc) {
        this.order = "desc";
      } else this.order = "asc";
      this.getData();
    },
    viewContract(id) {
      this.$router.push("/contract/view/" + id);
    },
    reportContract(id) {
      this.$router.push("/report/" + id);
    },
    async getData() {
      if (localStorage.getItem("userData")) {
        this.userData = JSON.parse(localStorage.getItem("userData"));
      }
      this.showLoading = true;
      const url =
        `?limit=${this.showEntrie}&page=${this.currentPage}&order=${this.order}&sort=${this.sort}&filter=` +
        JSON.stringify(this.arrayFilters);

      const resp = await ContractService.getContracts(url, this.$store);
      if (resp) {
        this.records = resp.rows;
        this.totalElements = resp.responseFilter.total_rows;
      }
      this.showLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
