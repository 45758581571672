/* eslint-disable*/
import VueStoreService from './VueStoreService';
const ContractService = {
    async getContracts(filter,store){
        var resp = await VueStoreService.get("api/contract"+filter, store)
        return resp;
    },
    async saveContract(data,store){
        var resp = await VueStoreService.post("api/contract",data,store)
        return resp;
    },
    async saveAction(data,store){
        var resp = await VueStoreService.post("api/action",data,store)
        return resp;
    },
    async updateContractDetail(detail_id,data,store){
        var resp = await VueStoreService.put("api/contract/detail/"+detail_id,data,store)
        return resp;
    },
    async updateContractSingle(detail_id,data,store){
        var resp = await VueStoreService.put("api/contract/single/"+detail_id,data,store)
        return resp;
    },
    async getContract(contract_id,store){
        var resp = await VueStoreService.get( "api/contract/"+contract_id,store)
        return resp;
    },
    async getTimeLine(contrail_detail_id,store){
        var resp = await VueStoreService.get( "api/action/timeline/"+contrail_detail_id,store)
        return resp;
    },
    async updateContract(contract_id,data,store){
        var resp = await VueStoreService.put("api/contract/"+contract_id,data,store)
        return resp;
    },

    async deleteContract(contract_id,store){
        var resp = await VueStoreService.delete("api/contract/"+contract_id,store)
        return resp;
    },
    async getContractsReport(contract_id,store){
        var resp = await VueStoreService.get("api/contract/detailParent/"+contract_id, store)
        return resp;
    },
    async sendEmailContract(data,store){
        var resp = await VueStoreService.post("api/contract/sendEmail",data,store);
        return resp;
    },
    async getBodyStringByPercentage(contract_id,store){
        var resp = await VueStoreService.get("api/contract/getStringByCompletion/"+contract_id, store)
        return resp;
    },
    async getContractUserEmailbyId(contract_id,store){
        var resp = await VueStoreService.get("api/contract/getContractUserEmailbyId/"+contract_id, store)
        return resp;
    }
}

export default ContractService;