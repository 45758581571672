/* eslint-disable*/
import VueStoreService from './VueStoreService';
const UserService = {
    async getUsers(filter,store){
        var resp = await VueStoreService.get("api/user"+filter, store)
        return resp;
    },
    async getUsersByCompany(filter,store){
        var resp = await VueStoreService.get("api/user/company/"+filter, store)
        return resp;
    },    
    async saveUser(data,store){
        var resp = await VueStoreService.post("api/auth/register",data,store)
        return resp;
    },
    async getUser(user_id,store){
        var resp = await VueStoreService.get( "api/user/"+user_id,store)
        return resp;
    },
    async updateUser(user_id,data,store){
        var resp = await VueStoreService.put("api/user/"+user_id,data,store)
        return resp;
    },

    async deleteUser(user_id,store){
        var resp = await VueStoreService.delete("api/user/"+user_id,store)
        return resp;
    },
    
    async usuariosEmail(filter,store){
        var resp = await VueStoreService.get("api/user/email/"+filter, store)
        return resp;
    }
}

export default UserService;